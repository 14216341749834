import React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';

import Avatar from '@mui/material/Avatar';
// import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';

// import RestoreIcon from '@mui/icons-material/Restore';
// import FavoriteIcon from '@mui/icons-material/Favorite';

import { useAuth } from '../../contexts/AuthContext';
// import { useHistory } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Gravatar from 'react-gravatar';
import PostFormDialog from '../posts/PostFormDialog';
// import useStorage from '../../hooks/useStorage';
// import TextField from '@mui/material/TextField';
// import { projectFirestore, timestamp } from '../../firebase';
// import Button from '@mui/material/Button';
// import { AiOutlinePicture } from 'react-icons/ai';
// import { PostAddSharp } from '@mui/icons-material';

// const StyledFab = styled(Fab)({
// 	position: 'absolute',
// 	zIndex: 1,
// 	top: -30,
// 	left: 0,
// 	right: 0,
// 	margin: '0 auto',
// });

export default function Header({ children }) {
	const { currentUser, open, handleClickOpen, handleClose } = useAuth();

	return (
		<React.Fragment>
			<CssBaseline />
			<Paper square sx={{ pb: '50px', mt: '5rem' }}>
				{children}
				<PostFormDialog
					open={open}
					// handleClickOpen={handleClickOpen}
					handleClose={handleClose}
				/>
			</Paper>
			<AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
				<Toolbar>
					<IconButton color="inherit" aria-label="open drawer">
						<Avatar>
							<Gravatar
								email={currentUser ? currentUser.email : 'love@love.com'}
							/>
						</Avatar>
					</IconButton>
					<StyledFab color="secondary" aria-label="add">
						<AddIcon onClick={handleClickOpen} />
					</StyledFab>
					<Box sx={{ flexGrow: 1 }} />

					{/* <IconButton color="inherit">
						<MoreIcon />
					</IconButton> */}
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
}

const StyledFab = styled(Fab)({
	position: 'absolute',
	zIndex: 1,
	top: -30,
	left: 0,
	right: 0,
	margin: '0 auto',
});
