import './App.css';
import React from 'react';

import Video from './components/Video';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Home from './components/Home';
import PrivateRoute from './components/PrivateRoute';
import Links from './components/charts/Links';
import Header from './components/layout/Header';
import Gallery from './components/Gallery';
// import Dashboard from './components/layout/Dashboard';
import ForgotPassword from './components/ForgotPassword';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import theme from './theme';
import SearchAppBar from './components/layout/SearchAppBar';
// import Alerts from './components/layout/Alerts';
// import { Video } from '@mux/mux-node';

function App() {
	return (
		<Router>
			<AuthProvider>
				<ThemeProvider theme={theme}>
					{/* <Alerts /> */}

					<SearchAppBar />
					<Header>
						<Container maxWidth="lg" sx={{ mt: 6 }}>
							<Switch>
								<Route exact path="/" component={Links} />

								<PrivateRoute exact path="/link" component={Home} />

								{/* <PrivateRoute path="/update-profile" component={UpdateProfile} /> */}
								<Route path="/signup" component={SignUp} />
								<Route path="/login" component={Login} />
								<Route path="/photos" component={Gallery} />

								<Route path="/video-player" component={Video} />
								<Route path="/forgot-password" component={ForgotPassword} />
							</Switch>
						</Container>
					</Header>
				</ThemeProvider>
			</AuthProvider>
		</Router>
	);
}

export default App;
