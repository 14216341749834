import React, { useState } from 'react';

import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { TextField, Container, Button, Typography, Box } from '@mui/material';

export default function Signup() {
	// const emailRef = useRef();
	// const passwordRef = useRef();
	// const passwordConfirmRef = useRef();
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [email, setEmail] = useState('');

	const { signup } = useAuth();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	async function handleSubmit(e) {
		e.preventDefault();

		if (password !== passwordConfirm) {
			return setError('Passwords do not match');
		}

		try {
			setError('');
			setLoading(true);
			await signup(email, password);
			history.push('/');
		} catch {
			setError('Failed to create an account');
		}

		setLoading(false);
	}

	return (
		<>
			{/* <Container>
				<Card>
					<Card.Body>
						<h2 className="text-center mb-4">Sign Up</h2>
						{error && <Alert variant="danger">{error}</Alert>}
						<Form onSubmit={handleSubmit}>
							<Form.Group id="email">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" ref={emailRef} required />
							</Form.Group>
							<Form.Group id="password">
								<Form.Label>Password</Form.Label>
								<Form.Control type="password" ref={passwordRef} required />
							</Form.Group>
							<Form.Group id="password-confirm">
								<Form.Label>Password Confirmation</Form.Label>
								<Form.Control
									type="password"
									ref={passwordConfirmRef}
									required
								/>
							</Form.Group>
							<Button disabled={loading} className="w-100" type="submit">
								Sign Up
							</Button>
						</Form>
					</Card.Body>
				</Card>
				<div className="w-100 text-center mt-2">
					Already have an account? <Link to="/login">Log In</Link>
				</div>
			</Container> */}
			<Container>
				<Box
					component="form"
					sx={{
						'& > :not(style)': { m: 1, width: '25ch' },
					}}
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
				>
					<Typography variant="h6" color="primary">
						Sign Up
					</Typography>
					<TextField
						id="standard-textarea"
						label="Email"
						sx={{ m: '0 auto' }}
						placeholder="Email"
						multiline
						variant="standard"
						type="text"
						name="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						// ref={emailRef}
					/>
					<TextField
						id="standard-textarea"
						label="Password"
						sx={{ m: '0 auto' }}
						placeholder="Password"
						multiline
						variant="standard"
						type="password"
						name="password"
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						// ref={passwordRef}
					/>

					<TextField
						id="standard-textarea"
						label="Confirm Password"
						sx={{ m: '0 auto' }}
						placeholder="Password"
						multiline
						variant="standard"
						type="password"
						name="passwordConfirm"
						// ref={passwordConfirmRef}
						onChange={(e) => setPasswordConfirm(e.target.value)}
						value={passwordConfirm}
					/>
					<Button
						type="submit"
						variant="contained"
						disabled={loading}
						gutterBottom
					>
						Sign Up
					</Button>

					<Box>
						Already have an account? <Link to="/login">Log In</Link>
					</Box>
					{error && <Typography>{error}</Typography>}
				</Box>
				{/* <div className="card card-body mt-5">
				<form onSubmit={onSubmit}>
					<div className="form-group">
						<button type="submit" className="btn btn-primary">
							Login
						</button>
					</div>
					<p>
						Don't have an account? <Link to="/register">Register</Link>
					</p>
				</form>
			</div> */}
			</Container>
		</>
	);
}
