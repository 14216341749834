import { Paper } from '@mui/material';

import React from 'react';

import PostCard from './PostCard';

// import { projectFirestore } from '../firebase';

const Post = ({ doc }) => {
	// const collectionRef = projectFirestore.collection('posts');
	// const [current, setCurrent] = useState(null);
	// const [posts, setPosts] = useState({
	// 	title: '',
	// 	text: '',
	// 	name: '',
	// 	image: ' imageAsFile',
	// });
	// const update = async () => {
	// 	// Set the 'capital' field of the city
	// 	const res = await collectionRef.doc(id).update({});
	// };
	// const deleteDoc = async () => {
	// 	const res = await collectionRef.doc(doc.id).delete();
	// 	console.log(res);
	// };
	// useEffect(() => {
	// 	if (current !== null) {
	// 		setPosts(current);
	// 	} else {
	// 		setPosts({
	// 			title: '',
	// 			text: '',
	// 			name: '',
	// 			image: ' imageAsFile',
	// 		});
	// 	}
	// }, [current]);
	return (
		<Paper elevation={0} square sx={{ pb: '50px', zIndex: 0 }}>
			<PostCard doc={doc} />
		</Paper>
	);
};

export default Post;
