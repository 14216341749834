import * as React from 'react';
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Gravatar from 'react-gravatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
// import { DeleteForeverSharp } from '@mui/icons-material';
import moment from 'moment';
import { projectFirestore } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { DeleteForeverSharp } from '@mui/icons-material';

const PostCard = ({ doc }) => {
	const { currentUser } = useAuth();

	const collectionRef = projectFirestore.collection('posts');
	const deleteDoc = async () => {
		const res = await collectionRef.doc(doc.id).delete();
		console.log(res, currentUser);
	};
	// console.log(currentUser);
	return (
		<Card
			sx={{
				maxWidth: '100%',
				borderBottom: '0.03rem solid lightgrey',
			}}
			elevation={0}
		>
			<CardHeader
				sx={{ color: '#aaa' }}
				avatar={
					<Avatar sx={{ bgcolor: '#0504aa00', mr: 'auto' }} aria-label="recipe">
						{/* <img
							width="30"
							height="30"
							src="https://i.pinimg.com/originals/d8/b0/ab/d8b0ab22b134aa22aa635cf66eb48c41.png"
							alt="infinity"
						/> */}
						<Gravatar email={doc?.email ? doc?.email : 'love@love.com'} />
					</Avatar>
				}
				subheader={`${moment(doc?.createdAt?.seconds * 1000).format(
					'MMM Do h:mm a'
				)}`}
				title={`${doc?.email ? doc.email : 'Anonymous'}  `}
				// subheader=""
			/>

			<CardContent>
				<Typography variant="body2" color="text.secondary">
					{doc?.posts?.text}
				</Typography>
			</CardContent>
			{doc?.image ? (
				<CardMedia
					component="img"
					sx={{ maxWidth: '768', height: 'auto' }}
					image={doc?.image}
					alt=""
				/>
			) : (
				''
			)}
			<CardActions disableSpacing>
				{doc?.likes?.size && `${doc?.likes.size}`}
				<IconButton aria-label="add to favorites">
					<FavoriteIcon />
				</IconButton>
				<IconButton aria-label="share">
					<ShareIcon />
				</IconButton>
				{currentUser?.uid === doc?.uid && (
					<IconButton
						sx={{ ml: 'auto' }}
						aria-label="share"
						onClick={deleteDoc}
					>
						<DeleteForeverSharp />
					</IconButton>
				)}
			</CardActions>
		</Card>
	);
};

export default PostCard;
