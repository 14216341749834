import React, { useState } from 'react';
// import useFirestore from '../../hooks/useFirestore';
import useStorage from '../../hooks/useStorage';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../contexts/AuthContext';
import { projectFirestore, timestamp } from '../../firebase';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { AiOutlinePicture } from 'react-icons/ai';
import { PostAddSharp } from '@mui/icons-material';
// import { toast, ToastContainer } from 'react-toastify';

// import LinkItem from './LinkItem';\

// const Input = styled('input')({
// 	display: 'none',
// });
const LinkForm = () => {
	const { currentUser } = useAuth();

	// const [error, setError] = useState('');
	const [imageAsFile, setImageAsFile] = useState('');
	const { url } = useStorage(imageAsFile && imageAsFile);

	const [posts, setPosts] = useState({
		text: '',
		name: '',
	});
	const { text, name } = posts;
	const collectionRef = projectFirestore.collection('posts');

	const onChange = (e) => {
		setPosts({ ...posts, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const createdAt = timestamp();
		try {
			await collectionRef.add({
				posts,
				image: url ? url : '',
				createdAt,
				uid: currentUser.uid,
				email: currentUser.email,
			});
			// await addPost(posts, url);

			setPosts({
				text: '',
				name: '',
			});
			setImageAsFile('');
		} catch (error) {
			// setError(error.message);
		}
	};

	return (
		<Box
			component="form"
			sx={{
				m: '1.5rem auto',
			}}
			noValidate
			autoComplete="off"
			onSubmit={onSubmit}
		>
			{!currentUser && (
				<TextField
					id="standard-textarea"
					label="Name"
					sx={{ m: '0 auto' }}
					placeholder="Enter your name"
					multiline
					variant="standard"
					name="name"
					value={name}
					// fullWidth
					onChange={onChange}
				/>
			)}

			<TextField
				id="standard-multiline-static"
				label="Write a message for Sharif"
				// defaultValue="Write a message for Sharif"
				multiline
				rows={4}
				variant="standard"
				fullWidth={true}
				value={text}
				onChange={onChange}
				name="text"
			/>
			{imageAsFile && imageAsFile.name}

			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'auto auto',
					alignItems: 'center',
				}}
			>
				<label htmlFor="icon-button-file">
					<input
						style={{ display: 'none' }}
						id="icon-button-file"
						type="file"
						accept="image/*"
						onChange={(e) => setImageAsFile(e.target.files[0])}
					/>
					<IconButton
						color="primary"
						aria-label="upload picture"
						component="span"
					>
						<AiOutlinePicture />
					</IconButton>
				</label>
				<Button
					sx={{ width: '10ch', border: 'none', ml: 'auto' }}
					// endIcon={<PostAddSharp />}

					type="submit"
					variant="outlined"
					disabled={text === ''}
				>
					<PostAddSharp />
				</Button>
			</Box>
		</Box>
	);
};

export default LinkForm;
