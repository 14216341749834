import React, { useState } from 'react';

import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
// import { toast } from 'react-toastify';
import {
	TextField,
	Typography,
	Box,
	AlertTitle,
	Container,
	Button,
} from '@mui/material';

export default function Login() {
	// const emailRef = useRef();
	// const passwordRef = useRef();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const { login } = useAuth();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	async function handleSubmit(e) {
		e.preventDefault();

		try {
			setError('');
			setLoading(true);
			await login(email, password);
			history.push('/');
		} catch {
			setError('Failed to log in');
		}

		setLoading(false);
	}
	console.log(error);
	// error && toast(error);
	return (
		<>
			{/* <ToastContainer /> */}
			{/* <Card>
				<Card.Body>
					<h2 className="text-center mb-4">Log In</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label>Email</Form.Label>
							<Form.Control type="email" ref={emailRef} required />
						</Form.Group>
						<Form.Group id="password">
							<Form.Label>Password</Form.Label>
							<Form.Control type="password" ref={passwordRef} required />
						</Form.Group>
						<Button disabled={loading} className="w-100" type="submit">
							Log In
						</Button>
					</Form>
					<div className="w-100 text-center mt-3">
						<Link to="/forgot-password">Forgot Password?</Link>
					</div>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2">
				Need an account? <Link to="/signup">Sign Up</Link>
			</div>
			*/}
			{error && <AlertTitle>{error}</AlertTitle>}
			<Container>
				<Box
					component="form"
					sx={{
						'& > :not(style)': { m: 1, width: '25ch' },
					}}
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
				>
					<Typography variant="h6" color="primary">
						Login
					</Typography>
					<TextField
						id="standard-textarea"
						label="Email"
						sx={{ m: '0 auto' }}
						placeholder="Email"
						multiline
						variant="standard"
						type="text"
						name="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
						// ref={emailRef}
					/>
					<TextField
						id="standard-textarea"
						label="Password"
						sx={{ m: '0 auto' }}
						placeholder="Password"
						multiline
						variant="standard"
						type="password"
						name="password"
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						required
					/>
					<Button
						type="submit"
						variant="contained"
						disabled={loading}
						gutterBottom
					>
						Login
					</Button>
					<Box>
						<Link to="/forgot-password">Forgot Password?</Link>
					</Box>
					<Box>
						Need an account? <Link to="/signup">Sign Up</Link>
					</Box>
				</Box>
				{/* <div className="card card-body mt-5">
				<form onSubmit={onSubmit}>
					<div className="form-group">
						<button type="submit" className="btn btn-primary">
							Login
						</button>
					</div>
					<p>
						Don't have an account? <Link to="/register">Register</Link>
					</p>
				</form>
			</div> */}
			</Container>
		</>
	);
}
