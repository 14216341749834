import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import useFirestore from '../hooks/useFirestore';

export default function Gallery() {
	const { docs } = useFirestore('images');

	return (
		<Box sx={{ overflowY: 'scroll' }}>
			<ImageList cols={3} sx={{ width: '100%', height: '100vh' }} gap={8}>
				{docs.map((item) => (
					<ImageListItem key={item.url}>
						<img
							src={`${item.url}?w=248&fit=crop&auto=format`}
							srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
							alt=""
							width="400"
							loading="lazy"
						/>
					</ImageListItem>
				))}
				{itemData.map((item) => (
					<ImageListItem key={item.img}>
						<img
							src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
							srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
							alt={item.title}
							loading="lazy"
						/>
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	);
}

const itemData = [
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601168/sharif/IMG_2082_lqg2rk.jpg',
		title: 'Breakfast',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601168/sharif/IMG_2083_xqgyn4.jpg',
		title: 'Burger',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601170/sharif/IMG_2086_gjclu8.jpg',
		title: 'Camera',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601172/sharif/IMG_2087_uf0tim.jpg',
		title: 'Coffee',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601173/sharif/IMG_2088_sau7qy.jpg',
		title: 'Hats',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601173/sharif/IMG_2089_ln1fdi.jpg',
		title: 'Honey',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601174/sharif/IMG_2091_od9qtt.jpg',
		title: 'Basketball',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601175/sharif/IMG_2094_g6ugau.jpg',
		title: 'Fern',
	},

	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601179/sharif/IMG_2107_f0g4wx.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601180/sharif/IMG_2112_pfsdqe.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634601180/sharif/IMG_2108_wadhsk.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634684957/sharif/IMG_2153_cvdwgk.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634684956/sharif/IMG_2079_rlfgng.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634684957/sharif/IMG_2154_gkedya.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634684957/sharif/IMG_2103_fyfgvs.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634684958/sharif/IMG_2104_b5bkj8.jpg',
		title: 'Bike',
	},
	{
		img: 'https://res.cloudinary.com/dfwvu4gct/image/upload/v1634684957/sharif/IMG_2078_gm1w4i.jpg',
		title: 'football',
	},
];
