// import { createStyles, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			// main: '#0365bc',
			main: '#6593a6',
		},
		secondary: {
			main: '#ccffeb',
		},
	},
	typography: {
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	spacing: 2,
	transitions: {
		easing: {
			// This is the most common easing curve.
			easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
			// Objects enter the screen at full velocity from off-screen and
			// slowly decelerate to a resting point.
			easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
			// Objects leave the screen at full velocity. They do not decelerate when off-screen.
			easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
			// The sharp curve is used by objects that may return to the screen at any time.
			sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
		},
	},
});

export default theme;
