import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import useStorage from '../../hooks/useStorage';
import TextField from '@mui/material/TextField';
import { projectFirestore, timestamp } from '../../firebase';
// import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { IconButton } from '@mui/material';
import { AiOutlinePicture } from 'react-icons/ai';
import { PostAddSharp } from '@mui/icons-material';

export default function PostFormDialog({ open, handleClose, children }) {
	// const [value, setValue] = React.useState('recents');
	const { currentUser } = useAuth();

	// const router = useHistory();
	// const [error, setError] = useState('');
	const [imageFile, setImageFile] = React.useState('');
	const { url } = useStorage(imageFile && imageFile);

	const [posts, setPosts] = React.useState({
		text: '',
		name: '',
	});
	const { text, name } = posts;
	const collectionRef = projectFirestore.collection('posts');

	const onChange = (e) => {
		setPosts({ ...posts, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const createdAt = timestamp();
		try {
			await collectionRef.add({
				posts,
				image: url ? url : '',
				createdAt,
				uid: currentUser.uid,
				email: currentUser.email,
			});
			// await addPost(posts, url);

			setPosts({
				text: '',
				name: '',
			});
			setImageFile('');
		} catch (error) {
			// setError(error.message);
		}
	};
	return (
		<div>
			{/* <Button variant="outlined" onClick={handleClickOpen}>
				Open form dialog
			</Button> */}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Send a message to Sharif</DialogTitle>
				<DialogContent>
					<DialogContentText>
						We thank you for sending your love and prayers.
					</DialogContentText>
					<Box
						component="form"
						sx={{
							m: '1.5rem auto',
						}}
						noValidate
						autoComplete="off"
						onSubmit={onSubmit}
					>
						{!currentUser && (
							<TextField
								id="standard-textarea"
								label="Name"
								sx={{ m: '0 auto' }}
								placeholder="Enter your name"
								multiline
								variant="standard"
								name="name"
								value={name}
								// fullWidth
								onChange={onChange}
							/>
						)}

						<TextField
							id="standard-multiline-static"
							label="Write a message for Sharif"
							// defaultValue="Write a message for Sharif"
							multiline
							rows={4}
							variant="standard"
							fullWidth={true}
							value={text}
							onChange={onChange}
							name="text"
						/>
						{imageFile && imageFile.name}

						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'auto auto',
								alignItems: 'center',
							}}
						>
							<label htmlFor="icon-button-file">
								<input
									style={{ display: 'none' }}
									id="icon-button-file"
									type="file"
									accept="image/*"
									onChange={(e) => setImageFile(e.target.files[0])}
								/>
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="span"
								>
									<AiOutlinePicture />
								</IconButton>
							</label>
							<Button
								sx={{ width: '10ch', border: 'none', ml: 'auto' }}
								// endIcon={<PostAddSharp />}

								type="submit"
								variant="outlined"
								disabled={text === ''}
							>
								<PostAddSharp />
							</Button>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					{/* <Button onClick={handleClose}>Subscribe</Button> */}
				</DialogActions>
			</Dialog>
		</div>
	);
}
