import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import useFirestore from '../../hooks/useFirestore';
import Post from '../Post';
import LinkForm from './LinkForm';
const Links = () => {
	const { currentUser } = useAuth();

	const { docs } = useFirestore('posts');
	const router = useHistory();

	console.log(docs, currentUser);

	return (
		<Box sx={{ borderBottom: '1px solid #333', pt: 3 }}>
			{!currentUser?.email ? (
				<Button onClick={() => router.push('/login')} size="large">
					Login to create a new post
				</Button>
			) : (
				<LinkForm />
			)}
			{docs && docs.map((doc) => <Post key={doc.id} doc={doc} id={doc.id} />)}
		</Box>
	);
};

export default Links;
