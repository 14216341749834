import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import {
	TextField,
	Typography,
	Box,
	// AlertTitle,
	Container,
	Button,
} from '@mui/material';
export default function ForgotPassword() {
	// const emailRef = useRef();
	const { resetPassword } = useAuth();
	const [error, setError] = useState('');
	const [email, setEmail] = useState('');

	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);

	async function handleSubmit(e) {
		e.preventDefault();

		try {
			setMessage('');
			setError('');
			setLoading(true);
			await resetPassword(email);
			setMessage('Check your inbox for further instructions');
		} catch {
			setError('Failed to reset password');
		}

		setLoading(false);
	}

	return (
		<>
			<Container>
				<Typography variant="h6" color="primary">
					Login
				</Typography>
				{error && <Alert variant="danger">{error}</Alert>}
				{message && <Alert variant="success">{message}</Alert>}
				<Box
					component="form"
					sx={{
						'& > :not(style)': { m: 1, width: '35ch' },
					}}
					noValidate
					autoComplete="off"
					onSubmit={handleSubmit}
				>
					<TextField
						id="standard-textarea"
						label="Email"
						sx={{ m: '0 auto' }}
						placeholder="Email"
						multiline
						variant="standard"
						type="text"
						name="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
						// ref={emailRef}
					/>

					<Button disabled={loading} variant="contained" type="submit">
						Reset Password
					</Button>
				</Box>
				<div>
					<Link to="/login">Login</Link>
				</div>
				<div>
					Need an account? <Link to="/signup">Sign Up</Link>
				</div>
			</Container>
		</>
	);
}
