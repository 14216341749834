import React from 'react';

import PostCard from './PostCard';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

export default function Home() {
	return (
		<>
			<CssBaseline />

			<Box sx={{ height: '100vh' }}>
				<PostCard />
			</Box>
		</>
	);
}
